import * as React from "react"
import Header from "../../../components/Header";
import '../../../templates/styles.scss';

// markup
const SupportPage = () => {

  const country = "en";
  const language = "en";

  return (
    <>
      <Header language={language} country={country} urlParts={["/en/support"]} />
      <div className="home-wrapper" style={{textAlign: "center"}}>
        <h1>Support</h1>        
        <p>If you have any issues using the site or app, please get in touch with our technology partner Marmelo/Four.  You can contact them by emailing <a href='mailto:luke@marmelodigital.com'>luke@marmelodigital.com</a></p>
      </div>
    </>    
  )
}

export default SupportPage
